import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';
import numeral from 'numeral';
import 'chartjs-plugin-streaming';

class ScrollingChartMaker {




    constructor(id) {
        this.id = id;
        this.TimeRange = "daily";
        this.Source = "all";
        this.GraphType = "line";
        this.WindowSize = 50;

        this.LastData = 0;
    }

    init() {
        console.log('ScrollingChartMaker::init for id '+ this.id);
        const popChart = document.getElementById(this.id);


        // Loading

        fetch("?stats="+this.TimeRange+"&source="+this.Source).then(response=>response.json()).then(data=>{
            this.LastData = data;
            this.makeChartPop(popChart,data);

        }).catch(error=>console.log(error));
        let that =this;

        setInterval(function(){
              fetch("?stats="+that.TimeRange+"&source="+that.Source).then(response=>response.json()).then(data=>{

                 that.update(data);

              }).catch(error=>console.log(error));
        }, 5000);

    }



    getDatasetByName(name)
    {
        let retVal = null;



        return retVal;
    }


    update(newData)
    {

       // this.lineChart.data.labels[this.lineChart.data.labels.length -1] = (newData.labels[0]);

        newData.datasets.forEach((dataset)=> {


            let oldDataSetIdx = -1;
             let i =0;
            this.lineChart.data.datasets.forEach((old)=>{

                if(old.label === dataset.label)
                    oldDataSetIdx =  i;
                i++;
            });

            if(oldDataSetIdx === -1)
            { // Must create a new one
                this.lineChart.data.datasets.push(dataset);
                return;
            }
       //   if(this.lineChart.data.datasets[oldDataSetIdx].data[this.lineChart.data.datasets[oldDataSetIdx].data.length-1].y !== dataset.data[0].y) // push ony if data is different
            this.lineChart.data.datasets[oldDataSetIdx].data.push(dataset.data[0]);

        });



        this.lineChart.update({
            preservation: true
        });
/*

        this.lineChart.data.labels.push(newData.labels[0]);

        let i = 0;
        this.lineChart.data.datasets.forEach((dataset) => {

            dataset.data.push(newData.datasets[i++].data[0]);

        });
        this.lineChart.update({
            preservation: true
        });*/
    }

    makeChartPop(popCanvas,data)
    {
        Chart.defaults.global.elements.line.fill = false;

        this.lineChart = new Chart(popCanvas, {
            type: this.GraphType,
            options:{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {

                    colorschemes: {

                        scheme: 'office.Flow6'

                    }

                },
                scales:{
                    yAxes: [
                        {

                            ticks: {
                                beginAtZero: true,
                                callback: function(label, index, labels) {
                                    return numeral(label).format('0,0a');
                                }
                            }

                        }
                    ],
                    xAxes: [{

                        type: 'realtime',
                        realtime: {         // per-axis options
                            duration: 100000,    // data in the past 20000 ms will be displayed

                            delay: 5000,        // delay of 1000 ms, so upcoming values are known before plotting a line
                            pause: false,       // chart is not paused
                            ttl: undefined,     // data will be automatically deleted as it disappears off the chart

                            // a callback to update datasets
                        }

                    }]
                }

            },
            data: data
        });
    }

}







export default ScrollingChartMaker;


