import './scss/app.scss';

// Main app
import ChartMaker from './chartMaker';
import ScrollingChartMaker from './scrollingChartMaker';


document.addEventListener('DOMContentLoaded', () => {

    let elements =  document.querySelectorAll(".trStats");


    let i = 0;
    for (i = 0; i < elements.length; ++i) {

       // elements[i].dataset.source
        let para = document.createElement("canvas");
        para.id = "chart" + i;
        elements[i].appendChild(para);

        let app = new ChartMaker(para.id);
        if(elements[i].dataset.mode === "scroll")
            app = new ScrollingChartMaker(para.id);


        app.TimeRange =  elements[i].dataset.graph;

        if( app.TimeRange === "hour")
        {
            console.log(app.TimeRange+"3");
            app.TimeUnit = "hour";
        }

        app.Source = elements[i].dataset.source;
        app.GraphType = elements[i].dataset.graphtype;
        app.init();

    }


});
