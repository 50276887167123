import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';
import numeral from 'numeral';


class ChartMaker {




    constructor(id) {
        this.id = id;
        this.TimeRange = "daily";
        this.Source = "all";
        this.GraphType = "line";
        this.TimeUnit = "day";
    }

    init() {
        console.log('ChartMaker::init for id '+ this.id);

        const popChart = document.getElementById(this.id);


        // Loading

        fetch("?stats="+this.TimeRange+"&source="+this.Source).then(response=>response.json()).then(data=>{
            this.makeChartPop(popChart,data);

        }).catch(error=>console.log(error));

   let that =this;
        setInterval(function(){
            fetch("?update=1&stats="+that.TimeRange+"&source="+that.Source).then(response=>response.json()).then(data=>{
                that.update(data);

            }).catch(error=>console.log(error));
        }, 10*1000);
    }



/// Updates only the last point

    update(newData)
    {
        this.lineChart.data.labels[this.lineChart.data.labels.length -1] = (newData.labels[0]);

        let i = 0;
        this.lineChart.data.datasets.forEach((dataset) => {

            dataset.data[dataset.data.length - 1] = (newData.datasets[i++].data[0]);

        });

        this.lineChart.update();
    }

    makeChartPop(popCanvas,data)
    {
        Chart.defaults.global.elements.line.fill = false;

        this.lineChart = new Chart(popCanvas, {
            type: this.GraphType,
            options:{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    streaming: false,
                    colorschemes: {

                        scheme: 'office.Flow6'

                    }

                },
                scales:{
                    yAxes: [
                        {
                            ticks: {
                                callback: function(label, index, labels) {
                                    return numeral(label).format('0,0a');
                                }
                            }

                        }
                    ],
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: this.TimeUnit
                        }
                    }]
                }

            },
            data: data
        });
    }

}







export default ChartMaker;


